import React from "react";
import "./SponsorsScrion.scss";
import img1 from "../../images/corporate_logo1.png";
import img2 from "../../images/corporate_logo2.png";
import img3 from "../../images/corporate_logo3.png";
import img4 from "../../images/corporate-logo-4.jpg";
import img5 from "../../images/corporate-logo-5.jpg";
import img6 from "../../images/corporate-logo-6.jpg";
import img7 from "../../images/corporate-logo-7.jpg";
import img8 from "../../images/corporate-logo-8.jpg";

export class Sponsors extends React.Component {
  constructor(args) {
    super(args);
  }

  render() {
    return (
      <div className="sponsor__wrapper">
        <div className="sponsor__content">
          <h2>Our Partners for TMM 2023</h2>
          <div className="divider"></div>
          <div className="sponsor_section_card">
            <div className="sponsor_section">
              <a
                href="https://www.allcargologistics.com/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img2}></img>
              </a>
              <a
                href="https://www.ambit.co/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img5}></img>
              </a>

              <a
                href=" https://www.bharatpetroleum.in/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img8}></img>
              </a>
              {/* <a href="https://www.capitaland.com/sites/ascendas-firstspace/index.html" target="_blank" className="sponsor_img">
                                <img src={img3}></img>
                            </a> */}
              <a
                href="https://www.chalethotels.com/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img1}></img>
              </a>
              <a target="_blank" className="sponsor_img">
                <img src={img6}></img>
              </a>
              <a
                href="https://www.iotl.com/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img4}></img>
              </a>

              <a
                href="https://www.primetals.com/"
                target="_blank"
                className="sponsor_img"
              >
                <img src={img7}></img>
              </a>
            </div>
          </div>
          <a className="btn" href="/partners/">
            view more
          </a>
        </div>
      </div>
    );
  }
}

export default Sponsors;
