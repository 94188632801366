import React from "react";
import "./vision.scss";

export class VisionSection extends React.Component {
  constructor(args) {
    super(args);
  }

  render() {
    return (
      <div className="visionSection_wrapper">
        <div className="visionSection_content">
          <div className="text">
            As part of your organisation or initiative, this may be a small
            outreach for you, but a giant leap forward for us.
          </div>
          <div className="btn_group">
            <div className="btn_g">
              <a href="/partner">
                <div className="btn_size">BECOME A CSR PARTNER </div>
              </a>
              {/* <a href=""><div>MESSAGE FROM THE CEO </div></a> */}
            </div>
            <div className="btn_g">
              {/* <a href=""><div>VIEW FUNDRAISING GOAL </div></a> */}
              <a href="https://www.unitedwaymumbai.org/ngo-1317">
                <div className="btn_size">DONATE ONLINE</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VisionSection;
