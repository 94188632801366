import React from "react";
import "./runners.scss";
import img1 from "../../images/runners.png";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loader from "../../assets/spinner.gif";
import { keys } from "../../config";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        centerMode: true,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 450,
      settings: {
        centerMode: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export class Runners extends React.Component {
  constructor(args) {
    super(args);
    this.state = {
      moneyRaised: 0,
      topContributors: [],
      contributors: [],
      isLoading: false,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.get(`${keys.api_url}`);
      console.log(response.data);
      this.setState((state) => {
        return { ...state, ...response.data, isLoading: false };
      });
    } catch (e) {
      this.setState({ isLoading: false });

      console.log(e);
    }
  };

  render() {
    const { topContributors } = this.state;
    let progressCompleted = 0;
    const moneyRaised = 5418621.7;
    if (moneyRaised > 0) {
      progressCompleted = ((moneyRaised / 30000000) * 100).toFixed(2);
    }

    const slides = topContributors.map((item) => (
      <div className="slide">
        <div className="supporter-card">
          <img src={item.img_src}></img>
          <h4>{item.name}</h4>
        </div>
      </div>
    ));

    return (
      <div className="runners__wrapper">
        <div className="runners__content">
          <div className="runners_section">
            <div className="runners_right_section">
              <h2>fundraiserS</h2>
              <div className="divider"></div>
              <div className="slides">
                {this.state.isLoading ? (
                  <img src={loader}></img>
                ) : (
                  <Slider {...settings}>{slides}</Slider>
                )}
              </div>

              <a className="fundraiser_button" href="/fundraisers">
                VIEW MORE
              </a>
              <div className="supportes_card">
                <div className="supporters_handing">
                  Donation towards the Chinmaya Mission{" "}
                </div>

                <div className="progressbar">
                  <div className="progressbar__track">
                    <div
                      className="progressbar__completed"
                      style={{ width: `${progressCompleted}%` }}
                    >{`₹${moneyRaised.toLocaleString("en-IN")}`}</div>
                  </div>
                </div>
                {/*      <div className="supporters_subhanding">ANONYMOUS</div>
                    <div className="supporters_date">15th September 2022</div> */}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <div className="runners_left_section">
                  <h2>RUNNERS</h2>
                  <div className="divider"></div>
                  <div className="runners_card">
                    <div className="runners_img">
                      <a
                        href="https://www.unitedwaymumbai.org/fundraiser/17346"
                        target="_blank"
                      >
                        <img src={img1}></img>
                      </a>
                    </div>
                    <a
                      href="https://www.unitedwaymumbai.org/fundraiser/17346"
                      target="_blank"
                    >
                      <div className="card_name">MANISHA KHEMLANI</div>
                    </a>
                    <a
                      href="https://www.unitedwaymumbai.org/fundraiser/17346"
                      target="_blank"
                    >
                      <div className="caed_text">
                        Chief Executive Officer <br />
                        Central Chinmaya Mission Trust
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="runners_right_section">
                  <h2>SUPPORTERS</h2>
                  <div className="divider"></div>
                  <div className="slides">
                    <Slider {...settings}>{slides}</Slider>
                  </div>
                  <div className="supportes_card">
                    <div className="supporters_handing">
                      Donation towards
                      <br />
                      <span> Chinmaya Mission</span>{" "}
                    </div>

                    <div className="progressbar">
                      <div className="progressbar__track">
                        <div
                          className="progressbar__completed"
                          style={{ width: `${progressCompleted}%` }}
                        >{`₹${moneyRaised}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Runners;
