import React from "react";
import "./about.scss";
import img1 from "../../images/Marathon_logo1.png"

export class AboutSection extends React.Component {
    constructor(args) {
        super(args);
    }
    render() {
        return (
            <div className="aboutSection_wrapper">
                <div className="aboutSection_content">
                    <div className="img_logo">
                        <img src={img1}></img>
                    </div>
                    <div className="sub_text">jnanam-seva-kaushalam</div>
                    <div className="text">Help bring a vision to life - a society wherein the spirit of service works in tandem with expertise and efficiency to set the foundation for a stronger, better world.</div>
                    <div className="text">The Bhagavad Gita counts kindness and compassion as among the best traits in a person and considers giving, as per one’s capability, a form of duty. Giving back meaningfully is a way of serving and uplifting society as a whole.</div>
                    <div className="text">When you reach out, you etch your name in the eternal sphere of goodness.</div>
                    <div className="btn_group">
                        <div className="btn_g">
                            <a href="https://www.unitedwaymumbai.org/ngo-1317"><div className="btn_size">VIEW FUNDRAISING GOAL </div></a>
                        </div>
                        <div className="btn_g">
                            <a href="/message-from-the-global-head"><div className="btn_size">MESSAGE FROM THE GLOBAL HEAD </div></a>
                        </div>
                        <br />
                        <div className="btn_g">
                            <a href="https://www.chinmayamission.com/"><div className="btn_size">Global Chinmaya Mission</div></a>
                        </div>

                    </div>

                </div>
            </div >
        );
    }
}

export default AboutSection;
