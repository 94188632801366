import React from "react";
import "./cardSection.scss";
import img1 from "../../images/card01.png";
import img2 from "../../images/initiative-chinmaya-vidyalaya.jpg";
import img3 from "../../images/card02.png";
import img4 from "../../images/initiative-healthcare.jpg";
import img5 from "../../images/initiative-green-initiative.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  // dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 450,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const data = [
  // {
  //     img: img1,
  //     heading: "Chinmaya Organization for Rural Development (CORD)",
  //     text: "Funds raised enrich key welfare programmes for at-risk women including community building, agricultural development, counselling, micro-financing, legal aid and youth empowerment.",
  //     link: "https://cordindia.in/"
  // },
  {
    img: img2,
    heading: "Chinmaya Vidyalayas",
    text: "Financial support enables merit-based scholarships at K-12 school level, so that children and young adults at a disadvantage are able to continue their quest for excellence in education.",
    link: "http://www.chinmayaeducationcell.org/",
  },
  {
    img: img3,
    heading: "Chinmaya Vishwa Vidyapeeth (CVV)",
    text: "Sponsorships aid the infrastructural development of a unique university that supplements innovative, global modules with time-tested Indian wisdom. Co-create a new movement in learning. ",
    link: "https://www.cvv.ac.in/",
  },
  {
    img: img4,
    heading: "Healthcare",
    text: "Funds raised enable access to basic healthcare through the provision of  subsidized treatments, diagnosis and medicines for those most in need, with a focus on rural areas. ",
    link: "",
  },
  {
    img: img5,
    heading: "Green Initiatives",
    text: "Financial aid helps increase the green footprint and preserve the planet for future generations. Enable sustainability initiatives such as water conservation via the development of water treatment plants and waste management systems.",
    link: "",
  },
];

export class CardSection extends React.Component {
  constructor(args) {
    super(args);
  }

  render() {
    const slides = data.map((item) => (
      <div className="initiatives__slider__slide">
        <div className="initiative__card card">
          <div className="card_img">
            <img src={item.img}></img>
          </div>
          <div className="card_text">
            <div className="card_heading">{item.heading}</div>
            <div className="ctext">{item.text}</div>
            <a href={item.link} target="_blank" className="btn">
              <span>LEARN MORE</span>
            </a>
          </div>
        </div>
      </div>
    ));

    return (
      <div className="card__wrapper">
        <div className="card__content">
          <div className="text">
            Funds raised at the Tata Mumbai Marathon will be utilized for the
            multi-fold implementation of key initiatives in education, women
            empowerment, healthcare and environmental conservation.
            <br />
            <br />
            Through the provision of high quality education, skill-based
            training, rural development support, welfare schemes and
            sustainability solutions - each Chinmaya initiative brings about
            positive change on multiple levels.
            <br />
            <br />
            When you sponsor us, you sponsor the lives of millions.
          </div>
          <div className="divider"></div>
          <div className="card_box">
            <Slider {...settings}>{slides}</Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default CardSection;
