import * as React from "react";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";
import VisionSection from "../components/VisionSection";
import Layout from "../components/Layout";
import CardSection from "../components/CardSection";
import Sponsors from "../components/Sponsors";
import Runners from "../components/Runners";
import FooterSection from "../components/FooterSection";
import { keys } from "../config";
import SEO from "../components/seo";
import Timer from "../components/Counter";
import SocialImpactSection from "../components/SocialImpactSection";
import TMMAtGlance from "../components/TMMAtGlance";

console.log(keys.api_url);

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Chinmaya Mission x TMM | Change is always a marathon"
        description="Chinmaya Mission is participating in the Tata Mumbai Marathon 2023 to raise funds for key initiatives in education and rural development. Reach out today."
        keywords="Chinmaya Mission, Tata Mumbai Marathon, Central Chinmaya Mission Trust, CCMT, fundraising, Chinmaya Vidyalaya, CORD, Chinmaya Vishwavidyapeeth, Chinmaya Trust"
        img={`https://marathon.chinmayamission.com/link-preview.jpg`}
      />
      <HeroSection />
      {/* <Timer deadline={new Date("15 Jan 2023")} /> */}
      <AboutSection />
      <CardSection />
      <SocialImpactSection />
      <TMMAtGlance />
      <Sponsors />
      <Runners />
      <VisionSection />
      <FooterSection />
    </Layout>
  );
};

export default IndexPage;
