import React from "react";
import "./footer.scss";
import img1 from "../../images/Marathon_logo.png";
import axios from "axios";
import swal from "sweetalert2";
import { keys } from "../../config";

export class FooterSection extends React.Component {
  constructor(args) {
    super(args);
    this.state = {
      email: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${keys.api_url}/signup`, { email: this.state.email });
      swal.fire({
        title: "Email submitted successfully. Thank you!",
        icon: "success",
        confirmButtonColor: "#12244A",
      });
      this.setState({ email: "" });
    } catch (e) {
      swal.fire({
        title: "Oops! some error occured. Please try again later.",
        icon: "error",
        confirmButtonColor: "#12244A",
      });
    }
  };

  render() {
    return (
      <div className="footerSection_wrapper">
        <div className="footerSection_content">
          <div className="footer_mid_section">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="footer__left">
                  <div className="left_img">
                    <img src={img1}></img>
                  </div>
                  <div className="left__text">
                    Central Chinmaya Mission Trust
                    <br />
                    Sandeepany Sadhanalaya
                    <br />
                    Saki Vihar Road Powai, Mumbai
                    <br />
                    PIN: 400 072
                    <br />
                    +91-22-2803 4900
                    <br />
                    ccmt@chinmayamission.com
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="footer__mid">
                  <div className="mid__menu">
                    <a href="/partners/">PARTNERS</a>
                  </div>
                  <div className="mid__menu">
                    <a href="https://www.chinmayaupahar.in/" target="_blank">
                      SHOP
                    </a>
                  </div>
                  <div className="mid__menu">
                    <a href="/contact/">CONTACT</a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="footer__right">
                  <form onSubmit={this.handleSubmit}>
                    <div className="right__signup">SIGN UP TO UPDATES</div>
                    <div className="signup">
                      <input
                        type="email"
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="signup__btn">
                      <button>SUBMIT</button>
                    </div>
                  </form>
                  <div className="social__box">
                    <div className="social_icons">
                      <span className="icon">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/ChinmayaMission"
                          target="_blank"
                          className=""
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </span>
                      <span className="icon">
                        <a
                          rel="noopener noreferrer"
                          href="https://twitter.com/ChinmayaMission"
                          target="_blank"
                          className=""
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </span>
                      <span className="icon">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/company/348832"
                          target="_blank"
                          className=""
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </span>
                      <span className="icon">
                        <a
                          rel="noopener noreferrer"
                          href="http://www.youtube.com/user/ChinmayaChannel"
                          target="_blank"
                          className=""
                        >
                          <i className="fa fa-youtube"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterSection;
