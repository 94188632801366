import React from "react";
import "./heroSection.scss";
import img1 from "../../images/CCMT_hero.png";
import ContentPage from "../content-page";
import PropTypes from "prop-types";
import Meta from "../meta";
import hero_desktop_1 from "../../images/hero-img-3.jpg";
import hero_desktop_5 from "../../images/hero-img-5.jpg";
import hero_desktop_2 from "../../images/hero-img-1.jpg";
import hero_desktop_3 from "../../images/hero-img-2.jpg";
import hero_desktop_4 from "../../images/hero-img-4.jpg";
import hero_desktop_6 from "../../images/hero-img-6.jpg";
import hero_desktop_7 from "../../images/hero-img-7.jpg";

import hero_mobile_7 from "../../images/hero-img-m-7.jpg";
import hero_mobile_5 from "../../images/hero-img-5.jpg";
import hero_mobile_6 from "../../images/hero-img-6.jpg";
import hero_mobile_1 from "../../images/hero-img-3.png";
import hero_mobile_2 from "../../images/hero-img-m-1.jpg";
import hero_mobile_3 from "../../images/hero-img-m-2.jpg";
import hero_mobile_4 from "../../images/hero-img-m-4.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  // dots: true,
  infinite: true,
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export class HeroSection extends React.Component {
  constructor(args) {
    super(args);
  }

  render() {
    const { location } = this.props;
    return (
      <ContentPage>
        <Meta title="" description="" location={location} />
        <div className="heroSection__wrapper">
          <div className="heroSection_content">
            <div className="heroimage">
              <div className="overlay"></div>
              <div className="hero-slider desktop">
                <Slider {...settings}>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_7}></img>
                  </div>{" "}
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_6}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_5}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_1}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_2}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_3}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_desktop_4}></img>
                  </div>
                </Slider>
              </div>

              <div className="hero-slider mobile">
                <Slider {...settings}>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_7}></img>
                  </div>{" "}
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_6}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_5}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_4}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_1}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_2}></img>
                  </div>
                  <div className="hero-slider__slide">
                    <img className="hero__img" src={hero_mobile_3}></img>
                  </div>
                </Slider>
              </div>

              <div className="img_text">
                <div className="h_img">
                  <img src={img1} alt=""></img>
                </div>
                <h1 className="h_text">108 times</h1>
                {/* <div className="divider"></div> */}
                <div className="sub_text">THE JOY-GIVING</div>

                <div className="hero_btn">
                  <a
                    href="https://www.unitedwaymumbai.org/ngo-1317"
                    target="_blank"
                  >
                    <span>DONATE NOW</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentPage>
    );
  }
}

HeroSection.propTypes = {
  location: PropTypes.object,
};

export default HeroSection;
