import React from "react";
import "./style.scss";
import img1 from "../../images/icons-01.png";
import img2 from "../../images/icons-02.png";
import img3 from "../../images/icons-03.png";
import img4 from "../../images/icons-04.png";

const SocialImpactSection = () => {
  return (
    <div className="socialimpact">
      <h2>Social Impact @ TMM 2023</h2>
      <div className="divider"></div>
      <div className="socialimpact__container">
        <div className="socialimpact__card">
          <img src={img1} alt="Social Impact Icon 1"></img>
          <h3>38+</h3>
          <p>Students received need-based scholarships</p>
        </div>
        <div className="socialimpact__card">
          <img src={img3} alt="Social Impact Icon 1"></img>
          <h3>600+</h3>
          <p>
            Chinmaya Vidyalaya students benefited from school amenities like
            smartboards, computers, desks, benches, school van
          </p>
        </div>
        <div className="socialimpact__card">
          <img src={img2} alt="Social Impact Icon 1"></img>
          <h3>800+</h3>
          <p>
            Students of Chinmaya Vishwa Vidyapeeth will reap the benefits of the
            infrastructure development
          </p>
        </div>
        <div className="socialimpact__card">
          <img src={img4} alt="Social Impact Icon 1"></img>
          <h3>100+</h3>
          <p>Families received livelihood opportunities under CORD</p>
        </div>
      </div>
    </div>
  );
};

export default SocialImpactSection;
