import React from "react";
import "./style.scss";
const TMMAtGlance = () => {
  return (
    <section className="atglance">
      <div className="atglance__container">
        <h2>TMM 2023 At A Glance</h2>
        <div className="divider"></div>
        <div className="atglance__card">
          <div className="atglance__card__number">₹2,59,09,928</div>
          <div className="atglance__card__label">
            Total funds raised for Chinmaya Initiatives
          </div>
        </div>
        <div className="atglance__card">
          <div className="atglance__card__number">300+</div>
          <div className="atglance__card__label">
            Indiviuals ran the marathon for Chinmaya Mission
          </div>
        </div>
        <div className="atglance__card">
          <div className="atglance__card__number">
            3<sup>rd</sup>
          </div>
          <div className="atglance__card__label">
            Amongst 250+ NGOs in fundraising
          </div>
        </div>
        <div className="atglance__card">
          <div className="atglance__card__number">72+</div>
          <div className="atglance__card__label">
            Indiviual fundraisers for the campaign
          </div>
        </div>
        <div className="atglance__card">
          <div className="atglance__card__number">
            1<sup>st</sup>
          </div>
          <div className="atglance__card__label">
            Fundraising in the Education sector
          </div>
        </div>
        <div className="atglance__card">
          <div className="atglance__card__number">7</div>
          <div className="atglance__card__label">
            Corporate partners for progress
          </div>
        </div>
      </div>
    </section>
  );
};

export default TMMAtGlance;
