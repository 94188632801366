
module.exports = {
  siteMetadata: {
    title: "Chinmaya Mission x TMM | Change is always a marathon",
    siteUrl: "https://marathon.chinmayamission.com",
    author: "",
    description: "Chinmaya Mission is participating in the Tata Mumbai Marathon 2023 to raise funds for key initiatives in education and rural development. Reach out today.",
    image: "https://marathon.chinmayamission.com/link-preview.jpg",
    facebookId: "",
    keywords: "Chinmaya Mission, Tata Mumbai Marathon, Central Chinmaya Mission Trust, CCMT, fundraising, Chinmaya Vidyalaya, CORD, Chinmaya Vishwavidyapeeth, Chinmaya Trust",
  },
  plugins: [
    "gatsby-plugin-sass",
    "gatsby-plugin-image",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sitemap",
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        icon: "src/images/favicon.png",
      },
    },
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: "./src/images/",
      },
      __key: "images",
    },
  ],
};
